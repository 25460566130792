export enum Locale {
  Bulgarian = 'bg',
  Croatian = 'hr',
  Czech = 'cs',
  English = 'en',
  French = 'fr',
  German = 'de',
  Hungarian = 'hu',
  Italian = 'it',
  Polish = 'pl',
  Portuguese = 'pt',
  Romanian = 'ro',
  Russian = 'ru',
  Serbian = 'sr',
  Slovak = 'sk',
  Spanish = 'es',
  Ukrainian = 'uk',
}

const SUPPORTED_DIALECTS: Array<Locale> = []

export const getLocale = (): Locale => {
  const { language } = window.navigator
  const [iSOLanguageCode, iSOCountryCode] = language.split('-')

  const preferredLanguage = (SUPPORTED_DIALECTS as string[]).includes(language)
    ? `${iSOLanguageCode}-${iSOCountryCode}`
    : iSOLanguageCode

  const isSupportedLocale = (locale: string): locale is Locale => {
    return Object.values(Locale).includes(locale as Locale)
  }

  return isSupportedLocale(preferredLanguage)
    ? preferredLanguage
    : Locale.English
}
