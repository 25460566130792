import React, { FunctionComponent, useEffect } from 'react'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { gql, useMutation } from '@apollo/client'
import { get } from 'utils'
import { Navigate } from 'react-router-dom'
import { intlMessageForId, locale, Locale } from 'localization'
import { LayoutDirection, Path } from 'types/enums'
import { FormName } from 'types/forms'
import Page from 'components/Page'
import Section from 'components/Section'
import Divider from 'components/Divider'
import { LinkButton, PrimaryButton, SecondaryButton } from 'components/Button'
import SocialDistancing from 'components/SocialDistancing'
import { Error } from 'components/Placeholders'
import {
  FormRadioButtons,
  FormSelect,
  REQUIRED_ERROR_MESSAGE,
  VALID_EMAIL_ERROR_MESSAGE,
} from 'components/FormElements'
import {
  EmailInput,
  FirstNameInput,
  LastNameInput,
} from 'components/FormElements/common'
import LocationFilter, {
  LocationAccessType,
  LocationFilterOption,
} from 'components/FormElements/LocationFilter'
import useLabsCustomerLocationFacet, {
  LocationFacetValue,
} from 'components/pages/labs/hooks/useLabsCustomerLocationFacet'
import useQuickScanLocationFacet from 'hooks/useQuickScanLocationFacet'
import { getUserBannerProps, UserStatus } from './utils'

const ADD_NEW_USER_MUTATION = gql`
  mutation(
    $email: String!
    $firstName: String!
    $lastName: String!
    $orgAdmin: Boolean!
    $language: Language
    $locations: [String]
    $qbenchCustomerIds: [Int]
  ) {
    createUser(
      input: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        orgAdmin: $orgAdmin
        language: $language
        locations: $locations
        qbenchCustomerIds: $qbenchCustomerIds
      }
    ) {
      successful
      messages {
        message
      }
    }
  }
`

export interface FormValues {
  [FormName.Email]: string
  [FormName.FirstName]: string
  [FormName.LastName]: string
  [FormName.OrgAdmin]: string
  [FormName.Language]: Locale
  [FormName.QuickscanAccessType]: LocationAccessType
  [FormName.QuickscanLocations]: string[]
  [FormName.LabsAccessType]: LocationAccessType
  [FormName.LabsLocations]: number[]
}

const initialValues: FormValues = {
  [FormName.Email]: '',
  [FormName.FirstName]: '',
  [FormName.LastName]: '',
  [FormName.OrgAdmin]: 'false',
  [FormName.Language]: locale,
  [FormName.QuickscanAccessType]: LocationAccessType.All,
  [FormName.QuickscanLocations]: [],
  [FormName.LabsAccessType]: LocationAccessType.All,
  [FormName.LabsLocations]: [],
}

export const validationSchema = Yup.object().shape({
  [FormName.Email]: Yup.string()
    .email(VALID_EMAIL_ERROR_MESSAGE)
    .required(REQUIRED_ERROR_MESSAGE),
  [FormName.FirstName]: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  [FormName.LastName]: Yup.string().required(REQUIRED_ERROR_MESSAGE),
})

export const HeaderElement: FunctionComponent = () => (
  <SocialDistancing spacing="16px" direction={LayoutDirection.Horizontal}>
    <LinkButton to={Path.AdminSettings_Users} Component={SecondaryButton}>
      {intlMessageForId('Buttons.Cancel')}
    </LinkButton>
    <PrimaryButton type="submit">
      {intlMessageForId('Buttons.SaveChanges')}
    </PrimaryButton>
  </SocialDistancing>
)

export const Profile: FunctionComponent<{
  renderRoleOptions?: boolean
  errorMessage?: string
  disableLanguage?: boolean
}> = ({ renderRoleOptions = true, errorMessage, disableLanguage = true }) => {
  const { setFieldError } = useFormikContext()

  useEffect(() => {
    setFieldError(FormName.Email, errorMessage)
  }, [errorMessage, setFieldError])

  return (
    <>
      <Divider height="8px" />
      <Section
        header={intlMessageForId('Admin.AddUser.SectionHeaders.Profile')}
      >
        <SocialDistancing spacing="24px">
          <SocialDistancing
            spacing="24px"
            direction={LayoutDirection.Horizontal}
          >
            <EmailInput />
            <FirstNameInput />
            <LastNameInput />
          </SocialDistancing>

          {renderRoleOptions && (
            <FormRadioButtons
              name={FormName.OrgAdmin}
              options={[
                {
                  label: intlMessageForId('Admin.RoleOptions.User'),
                  value: 'false',
                },
                {
                  label: intlMessageForId('Admin.RoleOptions.Admin'),
                  value: 'true',
                },
              ]}
              label={intlMessageForId('Admin.FieldLabels.Role')}
              displayError={false}
            />
          )}
          <FormSelect
            disabled={disableLanguage}
            style={{ width: 'fit-content' }}
            name={FormName.Language}
            options={[
              'English',
              'Bulgarian',
              'Croatian',
              'Czech',
              'French',
              'German',
              'Italian',
              'Polish',
              'Portuguese',
              'Romanian',
              'Russian',
              'Serbian',
              'Slovak',
              'Spanish',
              'Ukrainian',
            ].map((language) => ({
              label: intlMessageForId(`Admin.LanguageOptions.${language}`),
              value: Locale[language as keyof typeof Locale],
            }))}
            label={intlMessageForId('Admin.FieldLabels.Language')}
            displayError={false}
          />
        </SocialDistancing>
      </Section>
    </>
  )
}

const QuickscanLocationFilter: FunctionComponent<{
  quickScanLocations: Array<LocationFacetValue>
}> = ({ quickScanLocations }) => {
  const qsLocations: Array<LocationFilterOption> = quickScanLocations.map(
    (quickScanLocation) => {
      const { label, key } = quickScanLocation

      return {
        label,
        value: key,
      }
    }
  )

  return (
    <LocationFilter
      directions={intlMessageForId('Admin.AddUser.LocationAccess.Directions')}
      formNames={{
        accessType: FormName.QuickscanAccessType,
        locations: FormName.QuickscanLocations,
      }}
      locations={qsLocations}
    />
  )
}

const LabsLocationFilter: FunctionComponent<{
  locations: Array<LocationFacetValue>
}> = ({ locations }) => {
  const formattedLocations: Array<LocationFilterOption> = locations.map(
    (location) => {
      const { label, key } = location

      return {
        label,
        value: key.toString(),
      }
    }
  )

  return (
    <LocationFilter
      directions="Select the locations this user can see when viewing Lab Services data."
      formNames={{
        accessType: FormName.LabsAccessType,
        locations: FormName.LabsLocations,
      }}
      locations={formattedLocations}
    />
  )
}

export const Location: FunctionComponent = () => {
  const { location: locations } = useLabsCustomerLocationFacet()
  const renderLabsLocationFilter = locations.length > 0

  const { location: quickScanLocations } = useQuickScanLocationFacet()
  const renderQuickScanLocationFilter = quickScanLocations.length > 0
  return (
    <Section
      header={intlMessageForId('Admin.AddUser.SectionHeaders.LocationAccess')}
    >
      <SocialDistancing spacing="32px">
        {renderQuickScanLocationFilter && (
          <QuickscanLocationFilter quickScanLocations={quickScanLocations} />
        )}
        {renderLabsLocationFilter && (
          <LabsLocationFilter locations={locations} />
        )}
      </SocialDistancing>
    </Section>
  )
}

const AddUser: FunctionComponent = () => {
  const [addNewUser, { data, error }] = useMutation(ADD_NEW_USER_MUTATION)

  if (error) return <Error />

  if (get(data, 'createUser.successful') === true) {
    return (
      <Navigate
        to={Path.AdminSettings_Users}
        state={getUserBannerProps(UserStatus.Created)}
      />
    )
  }

  const errorMessage = get(data, 'createUser.messages[0].message')

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={(values: FormValues) => {
        const {
          EMAIL: email,
          FIRST_NAME: firstName,
          LAST_NAME: lastName,
          ORG_ADMIN: orgAdmin,
          LANGUAGE: language,
          QUICKSCAN_ACCESS_TYPE: accessType,
          QUICKSCAN_LOCATIONS: locations,
          LABS_ACCESS_TYPE: labsAccessType,
          LABS_LOCATIONS: labsLocations,
        } = values

        addNewUser({
          variables: {
            email,
            firstName,
            lastName,
            orgAdmin: orgAdmin === 'true',
            language,
            locations:
              accessType === LocationAccessType.Restricted ? locations : null,
            qbenchCustomerIds:
              labsAccessType === LocationAccessType.Restricted
                ? labsLocations.map(Number)
                : null,
          },
        })
      }}
    >
      {() => {
        const headerElement = <HeaderElement />
        return (
          <Form>
            <Page
              testId="AddUser"
              title={intlMessageForId('Admin.AddUser.Header')}
              headerElement={headerElement}
            >
              <Profile disableLanguage={false} errorMessage={errorMessage} />
              <Location />
            </Page>
          </Form>
        )
      }}
    </Formik>
  )
}

export default AddUser
